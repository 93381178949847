import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  distance?: string
  label?: string
  time?: string
}

export const Item = memo(function Item({ distance, label, time }: Props) {
  if (!label) {
    return null
  }

  return (
    <Container>
      <Label>{label}</Label>
      {distance || time ? (
        <Info row wrap>
          {distance ? (
            <Distance dial={4} row>
              {distance}
            </Distance>
          ) : null}
          {time ? (
            <Time dial={4} row>
              {time}
            </Time>
          ) : null}
        </Info>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 1.875rem;
  &:first-of-type {
    margin-top: 0;
  }
`

const Label = styled.div`
  font-weight: 600;
`

const Info = styled(FlexBox)`
  > div {
    &:nth-of-type(2) {
      &:before {
        content: '';
        width: 0.0625rem;
        height: 1.25rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        margin: 0 0.75rem;
      }
    }
  }
`

const Distance = styled(FlexBox)``

const Time = styled(FlexBox)``
