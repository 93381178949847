import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Item } from './Item'

export interface Props {
  item?: string[]
  image?: ImageProps
  title?: string
}

export const Stripe = memo(function Stripe({ item, image, title }: Props) {
  if (!item || !title) {
    return null
  }

  return (
    <Container row stretch wrap>
      <LeftSide dial={4}>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {item ? (
          <Wrap>
            {item ? (
              <FadeInUp>
                {item.map((e: any, i) => (
                  <Item key={i} {...e} />
                ))}
              </FadeInUp>
            ) : null}
          </Wrap>
        ) : null}
      </LeftSide>
      <RightSide>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.secondaryDark2};
  padding-top: 9rem;
  &:nth-of-type(even) {
    > div {
      &:first-of-type {
        order: 2;
      }
    }
  }

  @media (max-width: 1023px) {
    padding-top: 0;
  }
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 5rem 8.819vw 5rem 6.667vw;

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
    padding: 3rem 1.875rem 3.75rem;
  }
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-transform: uppercase;
`

const Wrap = styled.div`
  margin-top: 3.75rem;
  padding: 1.25rem 0 1.25rem 2.917vw;
  position: relative;
  &:before {
    content: '';
    width: 0.125rem;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.secondaryDark3};
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    margin-top: 2.25rem;
    padding-left: 1.875rem;
  }
`

const RightSide = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralDark3};
  width: 50%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-bottom: 50%;
  }

  @media (max-width: 767px) {
    padding-bottom: 66%;
  }
`
